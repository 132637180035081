import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './layout';
import { GetMetaTags } from './services/api.service';
import { AxiosResponse } from 'axios';
import { APIResponse } from './interfaces/api-response';
import { MetaTags } from './interfaces/meta-tags';

declare var $: any;
declare var AOS: any;

function App() {


  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('\n%cLast Updated at :: 04/02/2022#4 \n', 'color: red; font-size:1.2rem; font-weight:bold');

    window.addEventListener('load', () => {
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      })
    });
  }, []);

  useEffect(() => {
    const fetchMetaTags = async (): Promise<void> => {
      const resp: AxiosResponse<APIResponse> = await GetMetaTags();
      if (resp && resp.status === 201) {
        if (resp.data && resp.data.data) {
          const metaTags: MetaTags[] | [] = resp.data.data;

          let metaTagObj: MetaTags | undefined;

          switch (window.location.pathname) {
            case '/':
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'home');
              break;

            case '/contactus':
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'contact us');
              break;

            case "/work":
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'work');
              break;

            case "/services":
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'service');
              break;

            case "/company":
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'company');
              break;

            case "/product-details":
              metaTagObj = metaTags.find(e => e.page.toLowerCase() === 'product details');
              break;

            default:
              break;
          }


          $(document).ready(function () {
            if (metaTagObj) {
              $('meta[name=description]').attr('content', metaTagObj.metaDescriptions);
              $('meta[name=keywords]').attr('content', metaTagObj.metaKeywords);
              document.title = metaTagObj.pageTitle;
            }
          });
        }

      }
    }
    fetchMetaTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const HomePage = React.lazy(() => import('./pages/home'));
  const ContactUsPage = React.lazy(() => import('./pages/contact-us'));
  const WorkPage = React.lazy(() => import('./pages/work'));
  const ServicesPage = React.lazy(() => import('./pages/services'));
  const CompanyPage = React.lazy(() => import('./pages/company'));
  const ProductDetails = React.lazy(() => import('./pages/product-details'));

  return (
    <>
      <Router forceRefresh={true}>
        <Suspense fallback={
          <div className="preloader">
            <div className="spinner"> </div>
            <img id='preloader-logo' src={window.location.origin + "/img/jt-symbol-logo.png"} alt="loader" />
          </div>
        }
        >
          <Layout>
            <Router>
              <Switch>

                <Route exact path="/">
                  <HomePage />
                </Route>

                <Route exact path="/contactus">
                  <ContactUsPage />
                </Route>

                <Route exact path="/work">
                  <WorkPage />
                </Route>

                <Route exact path="/services">
                  <ServicesPage />
                </Route>

                <Route exact path="/company">
                  <CompanyPage />
                </Route>

                <Route exact path="/product-details">
                  <ProductDetails />
                </Route>

              </Switch>
            </Router>
          </Layout>
        </Suspense>
      </Router>
    </>
  )
}

export default App;
