
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { APIResponse } from '../interfaces/api-response';
import { ContactUsDetailsList } from '../interfaces/contact-us-Details';
import { GetContactUsDetails } from '../services/api.service';
import { isNullOrEmptyArray } from '../utils/null-checks';

function Footer() {

    const [contactUsDetails, setContactUsDetails] = useState<ContactUsDetailsList[] | []>([]);

    useEffect(() => {
        const fetchDefaultData = async () => {
            await fetchContactUsDetails();
        }
        fetchDefaultData();
    }, []);


    const fetchContactUsDetails = async () => {
        const resp: AxiosResponse<APIResponse> = await GetContactUsDetails();
        if (resp && resp.status === 201) {
            if (resp.data && resp.data.data && !isNullOrEmptyArray(resp.data.data)) {
                const contactListData: ContactUsDetailsList[] = resp.data.data;
                setContactUsDetails(contactListData);
            }
        }
    }

    return (
        <>
            <footer>
                <div className="footer-content container-fluid-small">
                    {/* <div className="logo-column">
                        <div className="logo-wr">
                            <img src={window.location.origin + "/img/footer-logo.svg"} alt=" " />
                        </div>
                    </div> */}

                    <div className="company-column">
                        <p className="footer-heading">Company</p>
                        <a href="/work" className="footer-link">Work</a>
                        <a href="/services" className="footer-link">Services</a>
                        <a href="/company" className="footer-link">About Us</a>
                    </div>

                    {/* <div className="insights-column">
                        <p className="footer-heading">Insights</p>
                        <a href="/" className="footer-link">Blog</a>
                        <a href="/" className="footer-link">Resources</a>
                        <a href="/" className="footer-link">Labs</a>
                    </div> */}

                    {
                        contactUsDetails && !isNullOrEmptyArray(contactUsDetails) &&
                        < div className="contact-column">
                            <p className="footer-heading">Contact us</p>
                            <a href={`tel:` + contactUsDetails[0].phoneNumber1} className="footer-link"> {contactUsDetails[0].phoneNumber1} </a>
                            <a href={`mailto:` + contactUsDetails[0].emailAddress1} target="_blank" className="footer-link" rel="noreferrer">
                                {contactUsDetails[0].emailAddress1}
                            </a>
                        </div>
                    }
                    <div className="icons-column">
                        <a href="https://www.twitter.com/Jarvis_Techno" className="footer-icon-link"><i className="fa fa-twitter"></i></a>
                        <a href="https://www.facebook.com/498711123977909" className="footer-icon-link"><i className="fa fa-facebook-f"></i></a>
                        <a href="https://www.linkedin.com/company/jarvis-technolabs/" className="footer-icon-link"><i className="fa fa-linkedin"></i></a>
                        <a href="https://www.instagram.com/jarvistechnolabs/" className="footer-icon-link"><i className="fa fa-instagram"></i></a>
                    </div>
                </div>

                <div className="footer-bottom">
                    Copyright © {new Date().getFullYear()} Jarvis Technolabs - All Rights Reserved.
                </div>
            </footer>
        </>
    )
}

export default Footer
