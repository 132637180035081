export const isNullOrUndefined = (s: any): boolean => {
    return s === null || s === undefined;
}

export const isNullOrEmptyArray = (s: any[]): boolean => {
    return isNullOrUndefined(s) || s.length === 0;
}

export const isStringNullEmptyOrUndefined = (str: string): boolean => {
    return isNullOrUndefined(str) || str === '';
}

export const isNullOrEmptyObject = (s: any): boolean => {
    return isNullOrUndefined(s) || Object.keys(s).length === 0;
}