import React, { useEffect } from 'react'
declare var $: any;

function Header() {

    useEffect(() => {
        window.onscroll = function () { manageStickyHeader() };

        $(window).scroll(function () {
            const $window = $(window);
            const $body = $('body, header');

            // Change 33% earlier than scroll position so colour is there when you arrive.
            const scroll = $window.scrollTop() + ($window.height() / 3);

            $('.panel').each((i: number, obj: any) => {
                if ($(obj).position().top <= scroll && $(obj).position().top + $(obj).height() > scroll) {

                    $body.removeClass((index: any, css: any) => {
                        return (css.match(/(^|\s)color-\S+/g) || []).join(' ');
                    });

                    if ($('.navbar-collapse').attr('style') !== 'display: block;') {
                        let color = 'white';

                        switch (($(obj).data('color'))) {
                            case 'white':
                            case '#F7F7F9':
                            case '#E8E8E8':
                            case 'litegrey1':
                                color = 'black'
                                break;

                            default:
                                break;
                        }
                        $('.nav-link').css('color', color);
                    } else {
                        $('.nav-link').css('color', 'black');
                    }


                    $('#baseLogo').attr('src', (
                        ($(obj).data('color')) === 'white' ||
                        ($(obj).data('color')) === 'litegrey1' ||
                        ($(obj).data('color')) === '#E8E8E8' ||
                        ($(obj).data('color')) === '#F7F7F9'
                    ) ? 'img/logo.svg' : 'img/logo-white.svg');

                    $('#menuIconLogo').attr('src', (
                        ($(obj).data('color')) === 'white' ||
                        ($(obj).data('color')) === 'litegrey1' ||
                        ($(obj).data('color')) === '#E8E8E8' ||
                        ($(obj).data('color')) === '#F7F7F9'
                    ) ? 'img/menu.svg' : 'img/menu-white.svg');


                    const customNav = document.getElementById('customNav');
                    if (customNav && customNav.classList.contains('right')) {
                        $('#baseLogo').attr('src', ('img/logo.svg'));
                    }

                    $body.css('background-color', $(obj).data('color'));
                }
            });
        }).scroll();

        $('#menuIconLogo').click(() => {
            $('.custom-navbar-collapse').toggleClass('right');
            $('.navbar-toggle').toggleClass('indexcity');

            setTimeout(() => {
                changeLogoOnMenuClose(true);
            }, 900);
        });
        $('#menuCloseIconLogo').click(() => {

            $('.custom-navbar-collapse').removeClass('right');
            $('.navbar-toggle').toggleClass('indexcity');

            changeLogoOnMenuClose(false);
        });
    });

    const changeLogoOnMenuClose = (skipCondition: boolean): void => {
        if (skipCondition) {
            $('#baseLogo').attr('src', ('img/logo.svg'));
        } else {
            const navbar: HTMLElement | null = document.getElementById('navbar');
            if (navbar) {
                console.log(navbar.style.backgroundColor);

                $('#baseLogo').attr('src', (
                    navbar.style.backgroundColor === '' ||
                    navbar.style.backgroundColor === 'white' ||
                    navbar.style.backgroundColor === 'litegrey1' ||
                    navbar.style.backgroundColor === '#E8E8E8' ||
                    navbar.style.backgroundColor === '#e8e8e8' ||
                    navbar.style.backgroundColor === 'rgb(247, 247, 249)' ||
                    navbar.style.backgroundColor === '#F7F7F9' ||
                    navbar.style.backgroundColor === '#f7f7f9'
                ) ? 'img/logo.svg' : 'img/logo-white.svg');
            }
        }
    }

    const manageStickyHeader = (): void => {
        const navbar: HTMLElement | null = document.getElementById("navbar");
        if (navbar) {
            const sticky = navbar.offsetTop;
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky");
            } else {
                navbar.classList.remove("sticky");
            }
        }
    }

    return (
        <header id="navbar" className="header-main">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <a className="navbar-brand logo" href="/" style={{ zIndex: 9999 }}>
                        <img src={window.location.origin + "/img/logo.svg"} id="baseLogo" alt="JTLogo" />
                    </a>

                    <button id="menu_bar" className="navbar-toggler" type="button">
                        <img id="menuIconLogo" src={window.location.origin + "/img/menu.svg"} alt="menuIcon" />
                    </button>

                    <div className="navbar-collapse custom-navbar-collapse" id='customNav'>
                        <div className="container">
                            <div className="px-3">
                                <div className="logo_mobile_head">
                                    {/* <a className="navbar-brand logo mobile_logo" href="/">
                                        <img src={window.location.origin + "/img/logo.svg"} alt="JTLogo" />
                                    </a> */}

                                    <button id="close_btn" className="navbar-toggler close_btn" type="button">
                                        <img src={window.location.origin + "/img/close-menu.svg"} alt="menuCloseIcon" id="menuCloseIconLogo" />
                                    </button>
                                </div>

                                <ul className="navbar-nav left ml-auto fillHoverEff">
                                    <li className="nav-item">
                                        <a className={`nav-link bdr_icon ${window.location.pathname === '/work' ? 'activeMenu' : ''}`} href="/work">
                                            Work
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link bdr_icon ${window.location.pathname === '/services' ? 'activeMenu' : ''}`} href="/services">
                                            Services
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link bdr_icon ${window.location.pathname === '/company' ? 'activeMenu' : ''}`} href="/company">
                                            Company
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link bdr_icon ${window.location.pathname === '/contactus' ? 'activeMenu' : ''}`} href="/contactus">
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header
