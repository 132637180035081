import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ContactUsUser } from "../interfaces/contact-us-user";

const axiosConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
}

export const APIURL = 'https://jt-staging-adminpanel-api.jarvistechnolabs.com'
// export const APIURL = 'http://localhost:3000'

axios.interceptors.request.use(
    config => {
        config.headers = axiosConfig.headers;
        return config;
    },
    error => {
        Promise.reject(error)
    });

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        Promise.reject(error)
    });


export const GetProducts = async (displayPage: string): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/product/get/products-for-website?displayPage=${displayPage}`);
}

export const GetBanners = async (pageName: string): Promise<AxiosResponse> => {
    return await axios.get(`${APIURL}/banner/get/banner-for-website?pageName=${pageName}`);
}

export const GetServices = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/service/get/service-for-website`);
}

export const GetContactUsDetails = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/contact-us-details/get/for-website`);
}

export const SaveContactUsUser = async (body: ContactUsUser): Promise<AxiosResponse> => {
    return await axios.post(APIURL + `/contact-us-users-details`, body);
}

export const GetEngagementModels = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/engagement-models/get/for-website`);
}

export const GetHowWeWork = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/how-we-work/get/for-website`);
}

export const GetWhyUs = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/whyUs/get/for-website`);
}

export const GetWeOffer = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/we-offer/get/for-website`);
}

export const GetTechnologyStack = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/technology-stack/get/for-website`);
}

export const GetExpertise = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/expertise/get/for-website`);
}

export const GetAboutUs = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/aboutUs/get/for-website`);
}

export const GetVisionMission = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/visionMission/get/for-website`);
}

export const GetourValues = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/ourValues/get/for-website`);
}

export const GetExperiencedTeam = async (): Promise<AxiosResponse> => {
    return await axios.get(APIURL + `/experiencedTeam/get/for-website`);
}

export const GetProductById = async (id: string): Promise<AxiosResponse> => {
    return await axios.get(`${APIURL}/product/get-by-id?id=${id}`);
}

export const GetMetaTags = async (): Promise<AxiosResponse> => {
    return await axios.get(`${APIURL}/meta-tags/lists`);
}
